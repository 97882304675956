<template>
    <div class="bg-gray-100 h-14 px-4 md:px-7">
      <div class="float-left flex items-center h-full">
        <h2 class="flex text-xl text-gray-900">
            <router-link v-if="link && nameLink" :to="link" class="text-blue-500 underline cursor-pointer mr-2"> 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
              </svg>
              {{ nameLink}} 
            </router-link> 
            <span v-if="link && nameLink" class="mr-2">/</span> 
            <router-link v-if="link_2 && nameLink_2" :to="link_2" class="text-blue-500 underline cursor-pointer mr-2"> 
              {{ nameLink_2 }} 
            </router-link> 
            <span v-if="link_2 && nameLink_2" class="mr-2">/</span> 
            <router-link v-if="link_3 && nameLink_3" :to="link_3" class="text-blue-500 underline cursor-pointer mr-2"> 
              {{ nameLink_3 }} 
            </router-link> 
            <span v-if="link_3 && nameLink_3" class="mr-2">/</span> {{ page }}
        </h2> 
      </div>
      <div class="float-right h-full md:ml-8">
        <h2 v-if="video || pdf" class="font-semibold text-sm pt-1 text-gray-900">
          Tutoriais: 
        </h2>
        <div class="flex items-center">
          <a v-if="video" target= "_blank" rel="noreferrer" :href="video">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="#060505" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="32" y="48" width="192" height="144" rx="16" transform="translate(256 240) rotate(180)" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect><line x1="160" y1="224" x2="96" y2="224" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><polygon points="160 120 112 88 112 152 160 120" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polygon></svg>
          </a>
          <a v-if="video2" target= "_blank" rel="noreferrer" :href="video2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="#060505" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="32" y="48" width="192" height="144" rx="16" transform="translate(256 240) rotate(180)" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect><line x1="160" y1="224" x2="96" y2="224" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><polygon points="160 120 112 88 112 152 160 120" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polygon></svg>
          </a>
          <a v-if="pdf" target= "_blank" rel="noreferrer" :href="pdf">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 ml-2" fill="#060505" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M48,128V40a8,8,0,0,1,8-8h96l56,56v40" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><polyline points="152 32 152 88 208 88" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><path d="M48,200H64a16,16,0,0,0,0-32H48v48" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><polyline points="216 168 188 168 188 216" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><line x1="212" y1="196" x2="188" y2="196" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M128,216a24,24,0,0,0,0-48H114v48Z" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
          </a>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        props: ['page', 'link', 'nameLink', 'link_2', 'nameLink_2', 'link_3', 'nameLink_3', 'pdf', 'video', 'video2']
    }
</script>